<template>
  <div class="modal-wrap">
    <div class="notice_wrap">
      <p class="title">{{ title }}</p>
      <p class="txt" v-html="content" />
    </div>
    <div class="modal_btn">
      <a
        v-if="cancelLabel"
        class="btn_b close-reveal-modal"
        @click="onClickClose"
      >
        {{ cancelLabel }}
      </a>
      <a
        :class="[cancelLabel ? 'btn_p' : 'btn_close', 'close-reveal-modal']"
        @click="onClickSubmit"
      >
        {{ submitLabel }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmPopup",
  data() {
    return {
      title: null,
      content: null,
      submitLabel: null,
      cancelLabel: null,
      submitCallback: null,
      cancelCallback: null,
    };
  },
  methods: {
    showPopup({
      title = this.$t("common.alert"),
      content = null,
      submitLabel = this.$t("common.submit"),
      cancelLabel = null,
      submitCallback = null,
      cancelCallback = null,
    }) {
      this.title = title;
      this.content = content;
      this.submitLabel = submitLabel;
      this.cancelLabel = cancelLabel;
      this.submitCallback = submitCallback;
      this.cancelCallback = cancelCallback;
    },
    closePopup() {
      this.$emit("close");
    },
    onClickClose() {
      this.closePopup();
      if (this.cancelCallback) {
        this.cancelCallback();
      }
    },
    onClickSubmit() {
      this.closePopup();
      if (this.submitCallback) {
        this.submitCallback();
      }
    },
  },
};
</script>
