<template>
  <section id="wrapCont">
    <router-view />
    <section id="check" class="backdrop" v-if="isConfirmPopupOpened">
      <confirm ref="confirmPopup" @close="onConfirmPopupClose" />
    </section>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import Confirm from "@/components/common/Confirm";

export default {
  name: "ContentWrapper",
  components: { Confirm },
  data() {
    return {
      isConfirmPopupOpened: false,
    };
  },
  async mounted() {
    await this.loadApplication();
    this.$EventBus.$on("confirm", this.confirm);
    this.$EventBus.$on("closeConfirm", this.closeConfirm);
  },
  beforeDestroy() {
    this.$EventBus.$off("confirm", this.confirm);
    this.$EventBus.$off("closeConfirm", this.closeConfirm);
  },
  methods: {
    ...mapActions(["loadApplication"]),
    async confirm(args) {
      this.isConfirmPopupOpened = true;
      await this.$nextTick();
      this.$refs.confirmPopup.showPopup(args);
    },
    closeConfirm() {
      if (!this.isConfirmPopupOpened) return;
      this.$refs.confirmPopup.closePopup();
    },
    onConfirmPopupClose() {
      this.isConfirmPopupOpened = false;
    },
  },
};
</script>
